import { Injectable, signal } from '@angular/core';

import type { SidePanelModel } from '../models/side-panel.model';

@Injectable({
  providedIn: 'root',
})
export class SidePanelService {
  private readonly _panel = signal<SidePanelModel>('none');

  public getPanel() {
    return this._panel.asReadonly();
  }

  public setPanel(value: SidePanelModel) {
    this._panel.set(value);
  }
}
